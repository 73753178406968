<template>
    <div class="page-wrapper">
        <div class="container-fluid p-0">
            <div class="authentication-main">
                <div class="row">
                    <div class="col-md-12">
                        <div class="auth-innerright">
                            <div class="authentication-box">
                                <div class="text-center"><img alt="" src="../assets/images/logo.png"></div>
                                <div class="card mt-4">
                                    <div class="card-body">
                                        <div class="text-center">
                                            <h4>Şifremi Unuttum</h4>
                                            <h6>Kayıtlı cep telefonunuz ile şifre değiştirme kodunuzu alınız.</h6>
                                        </div>
                                        <b-form @submit.prevent="onSubmit" class="theme-form" v-if="!isKodForm">
                                            <div class="form-group">
                                                <label class="col-form-label pt-0">Cep Telefonu</label>
                                                <input class="form-control" v-model="sifreTalep.GSM"
                                                    v-mask="'0(5##) ### ####'" @blur="v$.sifreTalep.GSM.$touch" type="text"
                                                    placeholder="Cep Telefonunuz">
                                                <p class="text-danger" v-if="v$.sifreTalep.GSM.$error">Cep telefonunuzu
                                                    yazınız.
                                                </p>
                                            </div>
                                            <div class="row mt-3 mb-0">
                                                <div class="col-md-6">
                                                    <router-link :to="'/auth/loginPage'"
                                                        class="btn btn-light btn-block">Giriş</router-link>
                                                </div>
                                                <div class="col-md-6">
                                                    <button class="btn btn-info btn-block" type="submit">Gönder</button>
                                                </div>
                                            </div>
                                        </b-form>
                                        <b-form @submit.prevent="onSubmitDegistir" class="theme-form" v-if="isKodForm">
                                            <div class="form-group">
                                                <label class="col-form-label pt-0">Doğrulama Kodu</label>
                                                <input class="form-control" v-model="sifreYenile.DogrulamaKodu"
                                                    v-mask="'####'" @blur="v$.sifreYenile.DogrulamaKodu.$touch"
                                                    type="text" placeholder="____">
                                                <p class="text-danger" v-if="v$.sifreYenile.DogrulamaKodu.$error">
                                                    Telefonunuza gelen 6 haneli doğrulama kodunu giriniz</p>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Yeni Şifre</label>
                                                <input autocomplete="" class="form-control" type="password"
                                                    @blur="v$.sifreYenile.sifre.$touch" v-model="sifreYenile.sifre"
                                                    placeholder="Yeni Şifreniz">
                                                <p class="text-danger" v-if="v$.sifreYenile.sifre.$error">Yeni şifrenizi
                                                    giriniz.
                                                </p>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Yeni Şifre (Tekrar)</label>
                                                <input autocomplete="" class="form-control" type="password"
                                                    v-model="sifreYenile.SifreTekrar"
                                                    @blur="v$.sifreYenile.SifreTekrar.$touch"
                                                    placeholder="Yeni şifrenizi tekrarını yazınız">
                                                <p class="text-danger" v-if="v$.sifreYenile.SifreTekrar.$error">Yeni şifre
                                                    tekrarınız uyuşmuyor.</p>
                                            </div>
                                            <div class="row mt-3 mb-0">
                                                <div class="col-md-6">
                                                    <router-link :to="'/auth/loginPage'"
                                                        class="btn btn-light btn-block">Giriş</router-link>
                                                </div>
                                                <div class="col-md-6">
                                                    <button class="btn btn-info btn-block" type="submit">Değiştir</button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { required, sameAs, minLength, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mask } from 'vue-the-mask'

export default {
    data() {
        return {
            sifreTalep: {
                GSM: ""
            },

            sifreYenile: {
                gsm: "",
                DogrulamaKodu: "",
                sifre: "",
                SifreTekrar: ""
            },

            isKodForm: false
        }
    },
    directives: {
        mask
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            sifreTalep: {
                GSM: { required }
            },
            sifreYenile: {
                DogrulamaKodu: { required, minLength: minLength(4), maxLength: maxLength(4) },
                sifre: { required },
                SifreTekrar: {
                    required,
                    sameAsPassword: sameAs(this.sifreYenile.sifre)
                },
            }
        }
    },
    methods: {
        showPassword: function () {
            if (this.type === 'password') {
                this.type = 'text';
            } else {
                this.type = 'password';
            }
        },
        async onSubmit() {
            let vm = this;

            const isValid = await this.$validationHelper.waitForValidation(this, this.v$.sifreTalep)
            if (isValid) {
                this.$connection.post('/auth/SifreIstek', this.sifreTalep, 'post', function (response) {
                    vm.isKodForm = true;
                });
            }
            else {
                vm.$alert.messageWithTitle('Şifremi Unuttum', 'Lütfen formu tam olarak doldurunuz.', 'error');
            }
        },
        async onSubmitDegistir() {
            let vm = this;

            const isValid = await this.$validationHelper.waitForValidation(this, this.v$.sifreYenile)
            if (isValid) {
                this.sifreYenile.gsm = this.sifreTalep.GSM;
                this.$connection.post('/auth/sifreBelirleme', this.sifreYenile, 'post', function (response) {
                    vm.$alert.messageWithFunction('Şifremi Unuttum', 'Şifreniz değiştirildi giriş yapabilirsiniz', 'success', function (){
                        vm.$router.push('/auth/login');
                    });
                });
            }
            else {
                vm.$alert.messageWithTitle('Şifremi Unuttum', 'Lütfen formu tam olarak doldurunuz.', 'error');
            }
        }
    }
}
</script>