<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="page-wrapper" id="canvas-bookmark">
      <!-- Page Header Start-->
      <div class="page-main-header" :class="{ open: toggle_sidebar_var }">
        <div class="main-header-right row">
          <div class="main-header-left d-lg-none">
            <div class="logo-wrapper"><router-link :to="{ name: 'default' }"><img src="../assets/images/logo.png"
                  alt=""></router-link></div>
          </div>
          <div class="mobile-sidebar">
            <div class="media-body text-right switch-sm">
              <label class="switch" v-on:click="toggle_sidebar">
                <feather type="align-left"></feather>
              </label>
            </div>
          </div>
          <div class="nav-right col">
            <ul class="nav-menus pull-right" :class="{ open: mobile_toggle }">
              <li><a href="javascript:void(0)" class="text-dark" v-on:click="toggle_fullscreen()">
                  <feather type="maximize"></feather>
                </a></li>
              <li class="onhover-dropdown">
                <div class="media align-items-center"><img class="align-self-center pull-right img-50 rounded-circle"
                    :src="getImgUrl()" alt="header-user">
                  <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span>
                  </div>
                </div>
                <ul class="profile-dropdown onhover-show-div p-20">
                  <li><a href="#">
                      <feather type="user"></feather> Edit Profile
                    </a></li>
                  <li><a href="#">
                      <feather type="mail"></feather> Inbox
                    </a></li>
                  <li><a href="#">
                      <feather type="lock"></feather> Lock Screen
                    </a></li>
                  <li><a href="#">
                      <feather type="settings"></feather> Settings
                    </a></li>
                  <li><a @click="logout">
                      <feather type="log-out"></feather> Logout
                    </a></li>
                </ul>
              </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" v-on:click="mobile_toggle = !mobile_toggle">
              <feather type="more-horizontal"></feather>
            </div>
          </div>
        </div>
      </div>
      <!-- Page Header Ends -->
    </div>
  </div>
</template>
<script>
var body = document.getElementsByTagName("body")[0];
import { mapState } from "vuex";

export default {
  name: 'Search',
  data() {
    return {
      terms: '',
      searchResult: false,
      searchResultEmpty: false,
      toggle_sidebar_var: false,
      clicked: false,
      toggle_rightsidebar_var: false,
      rightclicked: false,
      mobile_toggle: false,
      mobile_search: false,
      search: []
    }
  },
  computed: {
    ...mapState({
      menuItems: state => state.menu.data
    })
  },
  methods: {
    getImgUrl() {
      // console.log("localStorage.getItem('Userinfo').photoURL", localStorage.getItem('Userinfo'));

      return (localStorage.getItem('Userinfo') ? localStorage.getItem('Userinfo').photoURL : require("../assets/images/dashboard/user.png"))
    },
    searchTerm: function () {
      // this.$store.dispatch('menu/searchTerm', this.terms)
      let items = [];
      this.terms = this.terms.toLowerCase();
      this.menuItems.filter(menuItems => {
        if (menuItems.title.toLowerCase().includes(this.terms) && menuItems.type === 'link') {
          items.push(menuItems);
        }
        if (!menuItems.children) return false
        menuItems.children.filter(subItems => {
          if (subItems.title.toLowerCase().includes(this.terms) && subItems.type === 'link') {
            subItems.icon = menuItems.icon
            items.push(subItems);
          }
          if (!subItems.children) return false
          subItems.children.filter(suSubItems => {
            if (suSubItems.title.toLowerCase().includes(this.terms)) {
              suSubItems.icon = menuItems.icon
              items.push(suSubItems);
            }
          })
        })
        this.search = items
      });
    },

    logout: function () {
      this.$router.push('/auth/login');
      this.$user.logout();
    },
    addFix() {
      body.classList.add("offcanvas")
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove("offcanvas")
      this.searchResult = false;
      this.terms = ''
    },
    toggle_sidebar() {
      this.toggle_sidebar_var = !this.toggle_sidebar_var,
        this.clicked = !this.toggle_sidebar_var,
        this.$emit('clicked', this.clicked)
    },
    toggle_rightsidebar() {
      this.toggle_rightsidebar_var = !this.toggle_rightsidebar_var,
        this.rightclicked = !this.toggle_rightsidebar_var,
        this.$emit('rightclicked', this.rightclicked)
    },
    toggle_fullscreen() {
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  },
  watch: {
    search: function () {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.search.length)
        this.searchResultEmpty = true;
      else
        this.searchResultEmpty = false;
    }
  }
}
</script>
