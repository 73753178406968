export default {
    removeItem: function (arr, value) {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    },
    replaceItem: function(array, oldItem, newItem) {
        for (let i = 0; i < array.length; i++) {
            const index = array.indexOf(oldItem);
            if (~index) {
                array[index] = newItem;
            }
        }
        return array
    }
}