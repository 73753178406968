<template>
    <div class="page-wrapper">
        <div class="container-fluid p-0">
            <div class="authentication-main">
                <div class="row">
                    <div class="col-md-12">
                        <div class="auth-innerright">
                            <div class="authentication-box">
                                <div class="text-center"><img alt="" src="../assets/images/logo.png"></div>
                                <div class="card mt-4">
                                    <div class="card-body">
                                        <div class="text-center">
                                            <h4>Giriş</h4>
                                            <h6>Email ve şifrenizi yazınız</h6>
                                        </div>
                                        <b-form @submit.prevent="onSubmit" class="theme-form">
                                            <div class="form-group">
                                                <label class="col-form-label pt-0">Cep Telefonu</label>
                                                <input class="form-control" v-model="gsm" v-mask="'0(5##) ### ####'"
                                                    @blur="v$.gsm.$touch" type="text" placeholder="Cep Telefonunuz">
                                                <p class="text-danger" v-if="v$.gsm.$error">Cep telefonunuzu yazınız.
                                                </p>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Şifre</label>
                                                <input v-model="sifre" :type="type" class="form-control"
                                                    placeholder="Şifreniz" @blur="v$.sifre.$touch" />
                                                <div class="show-hide"><span class="show" @click="showPassword"> </span>
                                                </div>
                                                <p class="text-danger" v-if="v$.sifre.$error">Şifrenizi yazınız.
                                                </p>
                                            </div>
                                            <div class="form-group form-row mt-3 mb-0">
                                                <button class="btn btn-info btn-block" type="submit">Giriş</button>
                                            </div>
                                            <div class="row mt-3 mb-0">
                                                <div class="col-md-6">
                                                    <router-link :to="'/auth/kayit'" class="btn btn-primary btn-block">Kayıt
                                                        Ol</router-link>
                                                </div>
                                                <div class="col-md-6">
                                                    <router-link :to="'/auth/sifreunuttum'"
                                                        class="btn btn-light btn-block">Şifremi Unuttum</router-link>
                                                </div>
                                            </div>
                                        </b-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mask } from 'vue-the-mask'

export default {
    data() {
        return {
            type: 'password',
            gsm: "",
            sifre: "",
        }
    },
    directives: {
        mask
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            gsm: { required },
            sifre: { required }
        }
    },
    methods: {
        showPassword: function () {
            if (this.type === 'password') {
                this.type = 'text';
            } else {
                this.type = 'password';
            }
        },
        async onSubmit() {
            let vm = this;

            const isValid = await this.$validationHelper.waitForValidation(this, this.v$)
            if (isValid) {
                let data = {
                    "GSM": this.gsm,
                    "Sifre": this.sifre
                };
                this.$connection.post('/auth/LoginYonetici', data, 'post', function (response) {
                    vm.$user.setUser(response);
                    vm.$router.push('/');
                });
            }
            else {
                vm.$alert.messageWithTitle('Başvuru', 'Lütfen formu tam olarak doldurunuz.', 'error');
            }
        }
    }
}
</script>