<template>
    <div>
        <Breadcrumbs main="" title="Entegrasyon" />

        <div class="container-fluid">
            <div class="faq-wrap">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="header-faq">
                            <h5 class="mb-0">Otoarşiv Entegrasyon Bilgileri</h5>
                        </div>
                        <div class="row default-according style-1 faq-accordion" id="accordionoc">
                            <div class="col-xl-8 xl-60 col-lg-6 col-md-7">

                                <b-card no-body>
                                    <b-card-header header-tag="div" role="tab">
                                        <h5 class="mb-0">
                                            <feather type="help-circle"></feather> Genel Response Nesnesi
                                        </h5>
                                    </b-card-header>
                                    <b-card-body>
                                        <p>
                                            İstek sonucunda 200 (ok) status kodu aldığınızda aşağıdaki standart nesneyi
                                            kontrol
                                            etmelisiniz.
                                        </p>
                                        <table class="table table-striped table-hover">
                                            <tr>
                                                <th><code>Success</code></th>
                                                <td>true ise yapılan işlemin başarılı olduğunu belirtir.</td>
                                            </tr>
                                            <tr>
                                                <th><code>Message</code></th>
                                                <td>İşlemle ilgili mesajı içerir.</td>
                                            </tr>
                                            <tr>
                                                <th><code>Data</code></th>
                                                <td>İşleme dair dönen veriyi içerir.</td>
                                            </tr>
                                        </table>
                                    </b-card-body>
                                </b-card>

                                <b-card no-body>
                                    <b-card-header header-tag="div" role="tab">
                                        <h5 class="mb-0">
                                            <button v-b-toggle.collapseicon2 class="btn btn-link collapsed pl-0">
                                                <feather type="help-circle"></feather> Token
                                            </button>
                                        </h5>
                                    </b-card-header>
                                    <b-collapse id="collapseicon2" role="tabpanel">
                                        <b-card-body>
                                            <p>
                                                <code><b-badge variant="success">POST</b-badge> /Auth/Login</code>
                                            </p>
                                            <h5>Parametreler</h5>
                                            <p>
                                                Servisi kullanabilmeniz için token bilgisine ihtiyacınız olacaktır. Token
                                                bilgisi almak için aşağıdaki parametrelerle istek gönderebilirsiniz.<br>
                                                Entegrasyon sistemine kayıtlı cep telefonu ve entegrasyon şifreniz ile giriş
                                                yapabilirsiniz.<br>
                                                Dönen sonuç direk bir nesnede dönüş sağlar
                                            </p>
                                            <json-viewer :preview-mode="true" :value="requestAuth"></json-viewer>
                                            <h5>Response <b-badge variant="success">200 - OK</b-badge></h5>
                                            <table class="table">
                                                <tr>
                                                    <th><code>Token</code></th>
                                                    <td>Servis kullanımında headerda göndermenizi istediğimiz token bilgisi.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th><code>Expiration</code></th>
                                                    <td>Token'ın geçerlilik süresi.</td>
                                                </tr>
                                            </table>
                                            <json-viewer :preview-mode="true" :value="responseAuth"></json-viewer>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>

                                <b-card no-body>
                                    <b-card-header header-tag="div" role="tab">
                                        <h5 class="mb-0">
                                            <button v-b-toggle.collapseicon1 class="btn btn-link collapsed pl-0">
                                                <feather type="help-circle"></feather> Araç Bilgisi Sorgulama
                                            </button>
                                        </h5>
                                    </b-card-header>
                                    <b-collapse id="collapseicon1" role="tabpanel">
                                        <b-card-body>
                                            <p>
                                                <code><b-badge variant="success">POST</b-badge> /Sorgulama/Sorgu</code>
                                            </p>
                                            <h5>Parametreler</h5>
                                            <pre>Sorgu parametresinde aracın plaka veya şase numarası ile istekte bulunabilirsiniz.</pre>
                                            <json-viewer :preview-mode="true" :value="requestSorgulama"></json-viewer>
                                            <h5>Response <b-badge variant="success">200 - OK</b-badge></h5>
                                            <table class="table">
                                                <tr>
                                                    <th><code>SorguId</code></th>
                                                    <td>Yapılan sorgunun referans numarasıdır.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>CariyeIslendi</code></th>
                                                    <td>Eğer bu değer <code>true</code> geldi ise yapılan sorgu sonucu işlem
                                                        bedeli carinizden düşmüş anlamına gelir. <code>false</code>
                                                        değerinde ise sorgu ücretsiz yapıldığı anlamına gelir.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>Mesaj</code></th>
                                                    <td>Sorgulama sonucu ile ilgili mesajı döndürür.</td>
                                                </tr>
                                                <tr>
                                                    <th colspan="2"><code>Araclar (List)</code></th>
                                                </tr>
                                                <tr>
                                                    <th><code>AracId</code></th>
                                                    <td>Araç kaydının referansını alır.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>Plaka</code></th>
                                                    <td>Araç plakasını verir.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>Sase</code></th>
                                                    <td>Araç şase numarasını verir.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>Marka</code></th>
                                                    <td>Aracın marka bilgisini verir.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>Model</code></th>
                                                    <td>Aracın model bilgisini verir.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>ModelYili</code></th>
                                                    <td>Aracın model yılı bilgisini verir.</td>
                                                </tr>
                                                <tr>
                                                    <th colspan="2"><code>Hareketler (List)</code></th>
                                                </tr>
                                                <tr>
                                                    <th><code>Km</code></th>
                                                    <td>Aracın kilometre bilgisini içerir.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>Aciklama</code></th>
                                                    <td>Kaydı giren kişinin araçla ilgili açıklamasını içerir.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>HasarTutarMin</code></th>
                                                    <td>Kaydı giren kişinin girdiği minumum hasar tutarını içerir.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>HasarTutarMax</code></th>
                                                    <td>Kaydı giren kişinin girdiği maksimum hasar tutarını içerir.</td>
                                                </tr>
                                                <tr>
                                                    <th><code>KayitTarihi</code></th>
                                                    <td>Kaydın giriliş tarihini verir.</td>
                                                </tr>
                                            </table>
                                            <json-viewer :preview-mode="true" :value="responseSorgulama"></json-viewer>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import JsonViewer from 'vue-json-viewer'

export default {
    data() {
        return {
            requestAuth: { "GSM": "string", "Sifre": "string", },
            responseAuth: { "Token": "string", "Expiration": "2023-01-06T16:20:19" },

            requestSorgulama: { "Sorgu": "string" },
            responseSorgulama: { "Data": { "SorguId": 2346, "CariyeIslendi": true, "Mesaj": "1 adet araç kaydı bulundu.", "Araclar": [{ "AracId": 1210650, "Plaka": "59AFS454", "Sase": "WVGZZZ5NZLW402643", "Marka": "VOLKSWAGEN", "Model": "TIGUAN", "ModelYili": 2020, "Hareketler": [{ "Km": 12714, "Aciklama": "", "HasarTutariMin": 0, "HasarTutariMax": 0, "KayitTarihi": "2023-01-06T16:20:19" }] }] }, "Success": true, "Message": "Sorgulama Başarılı." }
        }
    },
    components: {
        JsonViewer
    }
}
</script>
