import connection from '../../helpers/connections';

const state = {
  bakiye: [],
  bugun: 0,
  sonYedi: 0,
  sonOtuz: 0
}

// getters
const getters = {

}

// mutations
const mutations = {
  setBakiye: (state, payload) => {
    state.bakiye = payload;
  },
  setBugun: (state, payload) => {
    state.bugun = payload;
  },
  setSonYedi: (state, payload) => {
    state.sonYedi = payload;
  },
  setSonOtuz: (state, payload) => {
    state.sonOtuz = payload;
  },
  setIstatistikArttir: (state, payload) => {
    state.bugun += payload;
    state.sonYedi += payload;
    state.sonOtuz += payload;
  }
};

// actions
const actions = {
  setBakiye: (context) => {
    connection.get('/musteri/bakiye', function (response) {
      context.commit('setBakiye', response);
    });
  },
  setIstatistik: (context) => {
    connection.get('/sorgulama/MusteriIstatistikBugun', function (response) {
      context.commit('setBugun', response);
    });
    connection.get('/sorgulama/MusteriIstatistikSonYedi', function (response) {
      context.commit('setSonYedi', response);
    });
    connection.get('/sorgulama/MusteriIstatistikSonOtuz', function (response) {
      context.commit('setSonOtuz', response);
    });
  },
  setBugun: (context) => {
    connection.get('/sorgulama/MusteriIstatistikBugun', function (response) {
      context.commit('setBugun', response);
    });
  },
  setSonYedi: (context) => {
    connection.get('/sorgulama/MusteriIstatistikSonYedi', function (response) {
      context.commit('setSonYedi', response);
    });
  },
  setSonOtuz: (context) => {
    connection.get('/sorgulama/MusteriIstatistikSonOtuz', function (response) {
      context.commit('setSonOtuz', response);
    });
  },
  setIstatistikArttir: (context, artim) => {
    context.commit('setIstatistikArttir', artim);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}