<template>
    <b-card header-tag="div">
        <h5 slot="header">Sorgu</h5>
        <b-form @submit.prevent="sorguSubmit" class="mb-3">
            <b-input-group prepend="Plaka veya Şase">
                <b-form-input v-model="sorgu.Sorgu" @blur="v$.sorgu.Sorgu.$touch"
                    placeholder="Plaka veya şase numarası ile sorgulayabilirsiniz"
                    :state="v$.sorgu.Sorgu.$error == false"></b-form-input>
                <b-input-group-append>
                    <b-button type="submit" variant="info">Sorgula</b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form>
        <hr>
        <div v-if="isSorguCekildi">

            <div class="row">
                <div class="col-sm-6 col-xl-6 col-lg-6">
                    <div class="card o-hidden">
                        <div class="bg-primary b-r-4 card-body">
                            <div class="media static-top-widget">
                                <div class="align-self-center text-center">
                                    <feather type="navigation" class="middle"></feather>
                                </div>
                                <div class="media-body"><span class="m-0">Cariye İşlendi</span>
                                    <h4 class="mb-0 counter">{{ sorgular.CariyeIslendi ? 'Ücret Yazıldı' : 'Ücretsiz' }}
                                    </h4>
                                    <feather type="navigation" class="icon-bg"></feather>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-6 col-lg-6">
                    <div class="card o-hidden">
                        <div class="bg-info b-r-4 card-body">
                            <div class="media static-top-widget">
                                <div class="align-self-center text-center">
                                    <feather type="alert-octagon" class="middle"></feather>
                                </div>
                                <div class="media-body"><span class="m-0">Mesaj</span>
                                    <h4 class="mb-0 counter">{{ sorgular.Mesaj }}</h4>
                                    <feather type="alert-octagon" class="icon-bg"></feather>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-right">
                    <b-button-group>
                        <b-button @click="kopyala()" variant="light">
                            <feather type="copy" class="middle"></feather> Kopyala
                        </b-button>
                        <b-button v-b-modal.modal-yazdir variant="warning">
                            <feather type="printer" class="middle"></feather> Yazdır
                        </b-button>
                    </b-button-group>
                </div>
            </div>

            <b-list-group class="mt-3">

                <b-list-group-item tag="a" v-for="item in sorgular.Araclar" :key="item.AracId"
                    @click="acikKayit = (acikKayit == item.AracId ? 0 : item.AracId)" :active="acikKayit == item.AracId"
                    href="#" class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ item.Plaka }}</h5>
                        <small>
                            {{ item.Hareketler.length }} Kayıt
                            <i class="fa fa-2x fa-angle-down" v-if="acikKayit != item.AracId"></i>
                            <i class="fa fa-2x fa-angle-up" v-if="acikKayit == item.AracId"></i>
                        </small>
                    </div>
                    <p class="mb-1">
                        Şase No : {{ item.Sase }}<br />
                        {{ item.Marka }} / {{ item.Model }} / {{ item.ModelYili }}
                    </p>

                    <div v-if="acikKayit === item.AracId" class="mt-3">
                        <h5>Aracın Hareketleri</h5>
                        <hr>
                        <b-list-group>
                            <b-list-group-item tag="a" href="#" class="flex-column align-items-start"
                                v-for="(hareket, index) in item.Hareketler" :key="index">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{ hareket.Km }} KM</h5>
                                    <small>
                                        <cTarihCell :kayitTarihi="hareket.KayitTarihi" :rowKey="index"></cTarihCell>
                                    </small>
                                </div>
                                <p class="mb-1" v-if="hareket.Aciklama !== ''">
                                    Açıklama : {{ hareket.Aciklama }}
                                </p>
                                <small v-if="hareket.HasarTutariMin != 0 && hareket.HasarTutariMax != 0">
                                    Tahmini Hasar Tutarı : <cMoneyCell :fiyat="hareket.HasarTutariMin" simge="₺">
                                    </cMoneyCell> - <cMoneyCell :fiyat="hareket.HasarTutariMax" simge="₺"></cMoneyCell>
                                </small>
                            </b-list-group-item>

                        </b-list-group>
                    </div>
                </b-list-group-item>

            </b-list-group>

            <b-form-textarea :value="sorgularText" ref="sorguText" rows="0" style="height: 0px;"></b-form-textarea>

            <b-modal id="modal-yazdir" size="lg" ref="modal" ok-title="Yazdır" cancel-title="Kapat" title="Yazdır"
                @ok="handleYazdir">
                <div class="card">
                    <div class="card-body">
                        <div class="invoice">
                            <div>
                                <div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="media">
                                                <div class="media-left">
                                                    <img class="media-object img-100" src="../assets/images/logo.png" alt />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="text-md-right">
                                                <h3>
                                                    Sorgu
                                                    <span class="digits counter">#{{ sorgular.SorguId }}</span>
                                                </h3>
                                                <p>
                                                    Sorgu Kelimesi
                                                    <span class="digits">{{ sorgu.Sorgu }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <b-list-group class="mt-3">
                                        <b-list-group-item tag="div" v-for="item in sorgular.Araclar" :key="item.AracId"
                                            href="#" class="flex-column align-items-start">
                                            <div class="d-flex w-100 justify-content-between">
                                                <h5 class="mb-1">{{ item.Plaka }}</h5>
                                                <small>
                                                    {{ item.Hareketler.length }} Kayıt
                                                </small>
                                            </div>
                                            <p class="mb-1">
                                                Şase No : {{ item.Sase }}<br />
                                                {{ item.Marka }} / {{ item.Model }} / {{ item.ModelYili }}
                                            </p>

                                            <h5>Aracın Hareketleri</h5>
                                            <hr>
                                            <b-list-group>
                                                <b-list-group-item tag="div" class="flex-column align-items-start"
                                                    v-for="(hareket, index) in item.Hareketler" :key="index">
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h5 class="mb-1">{{ hareket.Km }} KM</h5>
                                                        <small>
                                                            <cTarihCell :kayitTarihi="hareket.KayitTarihi" :rowKey="index">
                                                            </cTarihCell>
                                                        </small>
                                                    </div>
                                                    <p class="mb-1" v-if="hareket.Aciklama !== ''">
                                                        Açıklama : {{ hareket.Aciklama }}
                                                    </p>
                                                    <small
                                                        v-if="hareket.HasarTutariMin != 0 && hareket.HasarTutariMax != 0">
                                                        Tahmini Hasar Tutarı : <cMoneyCell :fiyat="hareket.HasarTutariMin"
                                                            simge="₺">
                                                        </cMoneyCell> - <cMoneyCell :fiyat="hareket.HasarTutariMax"
                                                            simge="₺"></cMoneyCell>
                                                    </small>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-list-group-item>

                                    </b-list-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </b-card>
</template>
<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import cMoneyCell from './c_money_cell.vue';
import cTarihCell from './c_tarih_cell.vue';

export default {
    props: {},
    data() {
        return {
            sorgu: {
                Sorgu: ''
            },

            sorgular: {},
            sorgularText: '',
            acikKayit: 0,
            isSorguCekildi: false
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            sorgu: {
                Sorgu: { required }
            }
        }
    },
    mounted() {

    },
    components: {
        cMoneyCell,
        cTarihCell
    },
    methods: {
        async sorguSubmit() {
            let vm = this;

            const isValid = await this.$validationHelper.waitForValidation(this, this.v$)
            if (isValid) {
                this.$connection.post('/sorgulama/Sorgu', vm.sorgu, 'post', function (response) {
                    console.log(response);
                    if (response.Success) {
                        let data = response.Data;

                        vm.sorgular = data;

                        for (let i = 0; i < data.Araclar.length; i++) {
                            const element = data.Araclar[i];
                            vm.sorgularText += (i + 1) + '. Kayıt : ' + element.Plaka + '\n';
                            vm.sorgularText += 'Şase No : ' + element.SaseNo + '\n';
                            vm.sorgularText += 'Marka : ' + element.Marka + ' / ' + element.Model + ' / ' + element.ModelYili + '\n';
                            vm.sorgularText += 'Hareketleri\n';
                            for (let j = 0; j < element.Hareketler.length; j++) {
                                const hareket = element.Hareketler[j];
                                vm.sorgularText += hareket.Km + ' Km - ' + vm.formatDate(hareket.KayitTarihi, 'dd.MM.yyyy') + '\n';
                                if (hareket.Aciklama !== '') {
                                    vm.sorgularText += 'Açıklama : ' + hareket.Aciklama + '\n';
                                }
                                if (hareket.HasarTutariMin !== 0 || hareket.HasarTutariMax !== 0) {
                                    vm.sorgularText += 'Tahmini Hasar Tutarı : ' + vm.formatHelper.formatMoney(hareket.HasarTutariMin) + '₺ - ' + vm.formatHelper.formatMoney(hareket.HasarTutariMax) + '₺\n';
                                }
                            }
                            if (i < data.Araclar.length - 1)
                                vm.sorgularText += '\n--------------\n\n';
                        }
                        vm.sorgularText = vm.sorgularText.trimEnd();

                        vm.isSorguCekildi = true;

                        if (data.CariyeIslendi) {
                            vm.$store.dispatch("musteri/setBakiye");
                            vm.$store.dispatch("musteri/setIstatistikArttir", 1);
                        }
                    } else {
                        vm.$alert.messageWithTitle('Sorgu', response.Message, 'error');
                    }
                });
            }
            else {
                vm.$alert.messageWithTitle('Sorgu', 'Lütfen formu tam olarak doldurunuz.', 'error');
            }
        },
        handleYazdir() {
            window.print();
        },
        kopyala() {
            this.$refs.sorguText.select();
            document.execCommand('copy');
            this.$toasted.show('Sorgu Kopyalandı', {
                theme: 'outline', position: 'bottom-right', closeOnSwipe: true, type: 'default', duration: 2000, className: 'alert-copy', action: {
                    icon: 'times',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                }
            });
        },
        formatDate(value, format) {
            if (value) {
                return this.$moment(String(value)).format(format)
            }
        }
    }
}
</script>
<style>
@media print {
    body * {
        visibility: hidden;
    }

    .invoice {
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        padding: 50px;
    }

    .invoice,
    .invoice * {
        visibility: visible;
        border: none;
    }
}
</style>