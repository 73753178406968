<template>
  <div>
    <Breadcrumbs main="Kontrol Paneli" title="" />

    <div class="container-fluid">
      <CMusteriIstatistik />

      <div class="row">
        <div class="col-md-12">
          <b-card header-tag="div">
            <h5 slot="header">Son Yaptığınız Sorgular</h5>
            <CSorguList />
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <CSorguYap />
        </div>
        <div class="col-md-6 col-xs-12">
          <COdeme />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import CSorguList from '@/components/c_sorgu_list.vue';
import CSorguYap from '@/components/c_sorgu_yap.vue';
import COdeme from '@/components/c_odeme.vue';
import CMusteriIstatistik from '@/components/c_musteri_istatistik.vue';

export default {
  data() {
    return {
      referenceNo: this.$route.query.referenceNo,
      referenceError: this.$route.query.referenceError
    }
  },
  computed: {

  },
  mounted() {
    this.$store.dispatch("musteri/setBakiye");
    this.$store.dispatch("musteri/setIstatistik");

    if (this.referenceNo !== '' && this.referenceNo != undefined) {
      this.odemeKontrol();
    }
    if (this.referenceError !== '' && this.referenceError !== undefined) {
      this.$alert.messageWithTitle('Ödeme', this.referenceError, 'error');
    }
  },
  watch: {

  },
  components: {
    CSorguList,
    CSorguYap,
    COdeme,
    CMusteriIstatistik
  },
  methods: {
    odemeKontrol() {
      let vm = this;

      this.$connection.get("/odeme/odemeKontrol?referansNo=" + this.referenceNo, function (response) {
        if (response.Success) {
          if (response.Data.Tutar > 0) {
            vm.$alert.messageWithTitle('Başarılı', "Bakiyeniz Yüklendi", 'success');
          } else {
            vm.$alert.messageWithTitle('Hata Aldık :(', response.Data.Aciklama, 'error');
          }
        } else {
          vm.$alert.messageWithTitle('Ödeme', response.Message, 'error');
        }
      });
    }
  }
}
</script>
