<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <b-form @submit="filtreSubmit" inline class="mb-3">
                    <label class="sr-only" for="filtre_kelime">Sorgu</label>
                    <b-form-input id="filtre_kelime" v-model="filtre.Sorgu" class="form-control digits mb-2 mr-sm-2 mb-sm-0"
                        placeholder="Plaka veya şase no yazabilirsiniz"></b-form-input>

                    <label class="sr-only" for="filtre_baslangictarihi">Başlangıç Tarihi</label>
                    <datepicker input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                        id="filtre_baslangictarihi" v-model="filtre.TarihIlk" placeholder="Başlangıç Tarihi" :format="date_format"></datepicker>

                    <label class="sr-only" for="filtre_bitistarihi">Bitiş Tarihi</label>
                    <datepicker input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                        id="filtre_bitistarihi" v-model="filtre.TarihSon" placeholder="Bitiş Tarihi" :format="date_format"></datepicker>

                    <b-button type="submit" variant="primary">Listele</b-button>
                </b-form>
            </div>
            <div class="col-md-4">
                <b-button-group class="pull-right">
                    <b-button variant="info" @click="excel()">
                        <i class="fa fa-file-excel-o"></i>
                        Excele Çıkart
                    </b-button>
                </b-button-group>
            </div>
        </div>

        <b-table striped hover :fields="sorguFields" :items="sorgular">
            <template #cell(OdemeDurumu)="data">
                <c-aktif-cell :aktif="data.value"></c-aktif-cell>
            </template>
            <template #cell(KayitTarihi)="row">
                <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :rowKey="row.item.Id"></c-tarih-cell>
            </template>
        </b-table>
    </div>
</template>
<script>
import c_money_cell from './c_money_cell.vue';
import Datepicker from 'vuejs-datepicker';
import C_aktif_cell from './c_aktif_cell.vue';

export default {
    props: {},
    data() {
        return {
            sorguFields:
                [
                    { key: 'Id', label: '#', sortable: false },
                    { key: 'SorguTipiStr', label: 'Sorgu Tipi', sortable: false },
                    { key: 'SorguKaydi', label: 'Sorgu', sortable: false },
                    { key: 'OdemeDurumu', label: 'Ödeme Durumu', sortable: false },
                    { key: 'KayitTarihi', label: 'Tarih', sortable: false }
                ],

            date_format: "dd.MM.yyyy",

            sorgular: [],

            filtre: {
                Sorgu: null,
                TarihIlk: null,
                TarihSon: null
            }
        }
    },
    mounted() {
        let ilkTarih = new Date();
        ilkTarih.setDate(-7);
        this.filtre.TarihIlk = ilkTarih;

        let sonTarih = new Date();
        this.filtre.TarihSon = sonTarih;

        this.list();
    },
    components: {
        Datepicker,
        c_money_cell,
        C_aktif_cell
    },
    methods: {
        filtreSubmit(event) {
            event.preventDefault();
            this.list();
        },
        list() {
            let vm = this;

            this.$connection.post("/sorgulama/Listele", vm.filtre, 'post', function (response) {
                if (response.Success) {
                    vm.sorgular = response.Data;
                } else {
                    vm.$alert.messageWithTitle('Sorgulama', response.Message, 'error');
                }
            });
        },
        excel() {
            let vm = this;

            this.$connection.postGetFile("/sorgular/listele/excel", vm.filtre, 'post', function (response) {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sorgular.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        }
    }
}
</script>