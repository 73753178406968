<template>
    <div class="row" v-if="mutateBakiye != null">
        <div class="col-sm-6 col-xl-3 col-lg-6">
            <div class="card o-hidden">
                <div class="bg-primary b-r-4 card-body">
                    <div class="media static-top-widget">
                        <div class="align-self-center text-center">
                            <feather type="navigation" class="middle"></feather>
                        </div>
                        <div class="media-body"><span class="m-0">Kalan Bakiyeniz</span>
                            <h4 class="mb-0 counter">{{ bakiye.Bakiye }} ₺</h4>
                            <feather type="navigation" class="icon-bg"></feather>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-3 col-lg-6">
            <div class="card browser-widget">
                <div class="media card-body p-3">
                    <div class="media-img"></div>
                    <div class="media-body align-self-center">
                        <div>
                            <p>Araç Sorgu Fiyatınız </p>
                            <h4>
                                <span class="counter">
                                    {{ bakiye.AracSorguFiyat }} ₺
                                </span>
                            </h4>
                        </div>
                        <div>
                            <p>Araç Detay Sorgu Fiyatınız</p>
                            <h4>
                                <span class="counter">
                                    {{ bakiye.AracDetaySorguFiyat }} ₺
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-2 col-lg-6">
            <div class="card o-hidden">
                <div class="bg-info b-r-4 card-body">
                    <div class="media static-top-widget">
                        <div class="align-self-center text-center">
                            <feather type="navigation" class="middle"></feather>
                        </div>
                        <div class="media-body"><span class="m-0">Bugün</span>
                            <h4 class="mb-0 counter">{{ bugun }}</h4>
                            <feather type="navigation" class="icon-bg"></feather>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-2 col-lg-6">
            <div class="card o-hidden">
                <div class="bg-info b-r-4 card-body">
                    <div class="media static-top-widget">
                        <div class="align-self-center text-center">
                            <feather type="navigation" class="middle"></feather>
                        </div>
                        <div class="media-body"><span class="m-0">Son Yedi</span>
                            <h4 class="mb-0 counter">{{ sonYedi }}</h4>
                            <feather type="navigation" class="icon-bg"></feather>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-2 col-lg-6">
            <div class="card o-hidden">
                <div class="bg-info b-r-4 card-body">
                    <div class="media static-top-widget">
                        <div class="align-self-center text-center">
                            <feather type="navigation" class="middle"></feather>
                        </div>
                        <div class="media-body"><span class="m-0">Son 1 Ay</span>
                            <h4 class="mb-0 counter">{{ sonOtuz }}</h4>
                            <feather type="navigation" class="icon-bg"></feather>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {},
    data() {
        return {
            mutateBakiye: null
        }
    },
    computed: {
        ...mapState({
            bakiye: state => state.musteri.bakiye,
            bugun: state => state.musteri.bugun,
            sonYedi: state => state.musteri.sonYedi,
            sonOtuz: state => state.musteri.sonOtuz
        })
    },
    mounted() {
        this.mutateBakiye = {
            Bakiye: this.$formatHelper.formatMoney(this.bakiye.Bakiye),
            AracSorguFiyat: this.$formatHelper.formatMoney(this.bakiye.AracSorguFiyat),
            AracDetaySorguFiyat: this.$formatHelper.formatMoney(this.bakiye.AracDetaySorguFiyat)
        }
    },
    methods: {

    }
}
</script>