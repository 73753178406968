<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{ 'loderhide': !loading }">
      <div class="loader bg-white">
        <div class="whirly-loader"> </div>
      </div>
    </div>
    <!-- Loader ends-->

    <!--<Main/>-->
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'app',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      loading: state => state.layout.loading
    })
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
