<template>
    <div>
        <Breadcrumbs main="Bakiye" title="Liste" :links="breadLinks" />

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-8">
                                    <b-form @submit="onFiltreSubmit" inline>
                                        <label class="sr-only" for="filtre_tip">Tip</label>
                                        <b-form-select id="filtre_tip" v-model="filtre.Tip" :options="filtre_tipler"
                                            class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                                        <label class="sr-only" for="filtre_baslangictarihi">Başlangıç Tarihi</label>
                                        <datepicker :language="tr"
                                            input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                                            id="filtre_baslangictarihi" v-model="filtre.BaslangicTarihi"
                                            placeholder="Başlangıç Tarihi" :format="date_format">
                                        </datepicker>

                                        <label class="sr-only" for="filtre_bitistarihi">Bitiş Tarihi</label>
                                        <datepicker :language="tr"
                                            input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                                            id="filtre_bitistarihi" v-model="filtre.BitisTarihi" placeholder="Bitiş Tarihi"
                                            :format="date_format">
                                        </datepicker>

                                        <b-button type="submit" class="pull-right" variant="warning">Listele</b-button>
                                    </b-form>
                                </div>
                                <div class="col-md-4 text-right">
                                    <h5>Kalan Bakiyeniz <c-money-cell :fiyat="bakiye" simge="₺"></c-money-cell></h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">

                            <c-table :tablefields="tablefields" :items="items">
                                <template #cell(Tutar)="data">
                                    <c-money-cell :fiyat="data.value" simge="₺"></c-money-cell>
                                </template>
                                <template #cell(KayitTarihi)="row">
                                    <c-tarih-cell :kayitTarihi="row.item.KayitTarihi"
                                        :rowKey="row.item.Id"></c-tarih-cell>
                                </template>
                            </c-table>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import { tr } from 'vuejs-datepicker/dist/locale'

export default {
    data() {
        return {
            breadLinks: [
            ],

            tablefields: [
                { key: 'TipStr', label: 'Tip', sortable: true, },
                { key: 'Aciklama', label: 'İşlem Açıklaması', sortable: true, },
                { key: 'Tutar', label: 'Tutar', sortable: true, },
                { key: 'ReferansId', label: 'Referans', sortable: true, thClass: 'sabitKol' },
                { key: 'KayitTarihi', label: 'Kayıt Tarihi', sortable: true }
            ],

            bakiye: {},
            items: [],

            date_format: "dd.MM.yyyy",
            tr: tr,

            filtre_tipler: [
                { value: null, text: 'Tipler' }
            ],

            filtre: {
                Tip: null,
                BaslangicTarihi: null,
                BitisTarihi: null
            }
        }
    },
    components: {
        Datepicker
    },
    mounted() {
        let baslangicTarihi = new Date();
        baslangicTarihi.setDate(-7);
        this.filtre.BaslangicTarihi = baslangicTarihi;

        let bitisTarihi = new Date();
        this.filtre.BitisTarihi = bitisTarihi;

        this.tipList();
        this.onList();
    },
    methods: {
        onFiltreSubmit(evt) {
            evt.preventDefault();
            this.onList();
        },
        tipList() {
            let vm = this;

            this.$connection.get("/odeme/GetAllTip", function (response) {
                if (response.Success) {
                    vm.filtre_tipler = vm.filtre_tipler.concat(response.Data.map(item => {
                        return {
                            value: item.Value,
                            text: item.Text
                        }
                    }));
                } else {
                    vm.$alert.messageWithTitle('Tipler', response.Message, 'error');
                }
            });
        },
        onList() {
            let vm = this;

            vm.filtre.Tip = vm.filtre.Tip !== null ? parseInt(vm.filtre.Tip) : vm.filtre.Tip;

            this.$connection.post("/odeme/listele", vm.filtre, "post", function (response) {
                if (response.Success) {
                    vm.bakiye = response.Data.Bakiye;
                    vm.items = response.Data.Odemeler;
                } else {
                    vm.$alert.messageWithTitle('Bakiye Listeleme', response.Message, 'error');
                }
            });
        }
    }
}
</script>