import user from './user'
import alert from "./alert"
import router from '../router';
import { store } from '../store';

const axios = require("axios");

//const _baseUrl = 'https://localhost:44346';
const _baseUrl = 'https://sorgu.otoarsiv.com';

export default {
    errorHandling: function (error, failFunc) {
        store.dispatch("layout/setLoading", false);

        if (error.response) {
            switch (error.response.status) {
                case 400:
                    if (failFunc !== undefined) {
                        failFunc(error);
                    } else {
                        alert.messageWithTitle('Hata :(', error.response.data, 'error');
                    }
                    break;

                case 401:
                    router.push('/auth/login');
                    break;

                case 500:
                    if (failFunc !== undefined) {
                        failFunc(error);
                    } else {
                        alert.messageWithTitle('Hata :(', error.response.data, 'error');
                    }
                    break;

                default:
                    if (failFunc !== undefined) {
                        failFunc(error);
                    } else {
                        alert.messageWithTitle('Hata :(', error.message, 'error');
                    }
                    break;
            }
        } else if (error.request) {
            console.log(error.request);

            if (failFunc !== undefined) {
                failFunc(error);
            } else {
                alert.messageWithTitle('Hata :(', error.request, 'error');
            }
        } else {
            if (failFunc !== undefined) {
                failFunc(error);
            } else {
                alert.messageWithTitle('Hata :(', error.message, 'error');
            }
        }
    },
    successHandling: function (response, successFunc, failFunc) {
        store.dispatch("layout/setLoading", false);

        if (response !== undefined) {
            //response var
            if (response.Success !== undefined) {
                //Result dönmüş
                if (response.Success) {
                    //Başarılı
                    if (successFunc !== undefined) {
                        successFunc(response);
                    } else {
                        alert.messageWithTitle('Başarılı', response, 'success');
                    }
                } else {
                    //hata almış
                    if (failFunc !== undefined) {
                        failFunc(response);
                    } else {
                        alert.messageWithTitle('Hata :(', response.data, 'error');
                    }
                }
            } else {
                if (successFunc !== undefined) {
                    successFunc(response);
                } else {
                    alert.messageWithTitle('Başarılı', "İşlem Yapıldı", 'success');
                }
            }
        } else {
            //Response yok
            if (successFunc !== undefined) {
                successFunc(response);
            } else {
                alert.messageWithTitle('Başarılı', "İşlem yapıldı.", 'success');
            }
        }
    },
    getToken: function () {
        var token = user.getToken();
        if (token !== null) {
            return token;
        }
    },
    setAuthorization: function (headers) {
        var token = this.getToken();
        if (token !== null)
            headers["Authorization"] = "Bearer " + token;
    },
    get: function (url, successFunc, failFunc) {
        let headers = {};
        this.setAuthorization(headers);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: _baseUrl + url,
            headers
        };

        let vm = this;

        store.dispatch("layout/setLoading", true);

        axios.request(config)
            .then((response) => {
                vm.successHandling(response.data, successFunc, failFunc);
            })
            .catch(function (error) {
                vm.errorHandling(error, failFunc);
            });

    },
    post: function (url, data, method, successFunc, failFunc) {
        method = method === "" || method === null || method === undefined ? 'post' : method;
        let dataStr = JSON.stringify(data);

        let headers = {
            'Content-Type': 'application/json'
        };
        this.setAuthorization(headers);

        let config = {
            method: method,
            maxBodyLength: Infinity,
            url: _baseUrl + url,
            headers,
            data: dataStr
        };

        let vm = this;

        store.dispatch("layout/setLoading", true);

        axios.request(config)
            .then((response) => {
                vm.successHandling(response.data, successFunc, failFunc);
            })
            .catch(function (error) {
                vm.errorHandling(error, failFunc);
            });
    },
    postData: function (url, data, method, successFunc, failFunc) {
        method = method === "" || method === null || method === undefined ? 'post' : method;

        let headers = {
            'Content-Type': 'application/json'
        };
        this.setAuthorization(headers);

        const FormData = require('form-data');

        var formData = new FormData();
        for (var key in data) {
            if ((typeof data[key]) === "object" && key.indexOf('Fl') === -1) {
                formData.append(key, this.isEmpty(JSON.stringify(data[key])));
            } else {
                formData.append(key, this.isEmpty(data[key]));
            }
        }

        let config = {
            method: method,
            maxBodyLength: Infinity,
            url: _baseUrl + url,
            headers,
            data: formData
        };

        let vm = this;

        store.dispatch("layout/setLoading", true);

        axios.request(config)
            .then((response) => {
                vm.successHandling(response.data, successFunc, failFunc);
            })
            .catch(function (error) {
                vm.errorHandling(error, failFunc, vm);
            });
    },
    delete: function (url, successFunc, failFunc) {
        let headers = {};
        this.setAuthorization(headers);

        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: _baseUrl + url,
            headers
        };

        let vm = this;

        store.dispatch("layout/setLoading", true);

        axios.request(config)
            .then((response) => {
                vm.successHandling(response.data, successFunc, failFunc);
            })
            .catch(function (error) {
                vm.errorHandling(error, failFunc, vm);
            });
    },

    isEmpty: function (str) {
        if (typeof str === "string" && str.trim().length === 0) {
            return '';
        } else if (str === null || str === 'null') {
            return '';
        } else {
            return str;
        }
    }
}