<template>
    <!-- page-wrapper Start-->
    <div class="page-wrapper">
        <div class="container-fluid">
            <!-- sign up page start-->
            <div class="authentication-main">
                <div class="row">
                    <div class="col-sm-12 p-0">
                        <div class="auth-innerright">
                            <div class="authentication-box">
                                <div class="text-center"><img src="../assets/images/logo.png" alt=""></div>
                                <div class="card mt-4 p-4">
                                    <h4 class="text-center">MÜŞTERİ KAYDI</h4>
                                    <h6 class="text-center">Başvurunuz onaylandıktan sonra bilgilendirileceksiniz</h6>
                                    <b-form @submit.prevent="onSubmit" class="theme-form">
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">Unvan</label>
                                                    <input class="form-control" @blur="v$.kayit.Unvan.$touch"
                                                        v-model="kayit.Unvan" type="text" placeholder="Firma unvanınız">
                                                    <p class="text-danger" v-if="v$.kayit.Unvan.$error">Firma unvanını
                                                        doldurunuz.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">Yetkili Kişi</label>
                                                    <input class="form-control" type="text"
                                                        @blur="v$.kayit.YetkiliKisi.$touch" v-model="kayit.YetkiliKisi"
                                                        placeholder="Yetkili Kişi">
                                                    <p class="text-danger" v-if="v$.kayit.YetkiliKisi.$error">Yetkili ismini
                                                        yazınız.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Cep Telefonu</label>
                                            <input class="form-control" v-model="kayit.GSM" v-mask="'0(5##) ### ####'" type="text"  @blur="v$.kayit.GSM.$touch"
                                                placeholder="Cep Telefonunuz">
                                            <p class="text-danger" v-if="v$.kayit.GSM.$error">Cep telefonunuzu yazınız.</p>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Email</label>
                                            <input class="form-control" type="email" @blur="v$.kayit.Email.$touch"
                                                v-model="kayit.Email" placeholder="Email adresiniz">
                                            <p class="text-danger" v-if="v$.kayit.Email.$error">Geçerli bir email adresi
                                                yazınız.</p>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">Şifre</label>
                                                    <input autocomplete="" class="form-control" type="password"
                                                        @blur="v$.kayit.Sifre.$touch" v-model="kayit.Sifre"
                                                        placeholder="Şifreniz">
                                                    <p class="text-danger" v-if="v$.kayit.Sifre.$error">Şifrenizi giriniz.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">Şifre (Tekrar)</label>
                                                    <input autocomplete="" class="form-control" type="password"
                                                        v-model="kayit.SifreTekrar" @blur="v$.kayit.SifreTekrar.$touch"
                                                        placeholder="Şifre tekrarını yazınız">
                                                    <p class="text-danger" v-if="v$.kayit.SifreTekrar.$error">Şifre
                                                        tekrarınız uyuşmuyor.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Telefon</label>
                                            <input class="form-control" v-model="kayit.Tel" v-mask="'(###) ### ## ##'" type="text"
                                                placeholder="Telefon numaranız">
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Adres</label>
                                            <textarea class="form-control" @blur="v$.kayit.Adres.$touch"
                                                v-model="kayit.Adres" placeholder="Firma adresiniz"></textarea>
                                            <p class="text-danger" v-if="v$.kayit.Adres.$error">Firma adresinizi giriniz.
                                            </p>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">Vergi Dairesi</label>
                                                    <input autocomplete="" class="form-control" type="text"
                                                        @blur="v$.kayit.VergiDairesi.$touch" v-model="kayit.VergiDairesi"
                                                        placeholder="Vergi Dairesi">
                                                    <p class="text-danger" v-if="v$.kayit.VergiDairesi.$error">Vergi
                                                        dairenizi giriniz.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">Vergi No</label>
                                                    <input autocomplete="" class="form-control" type="text"
                                                        @blur="v$.kayit.VergiNumarasi.$touch"  v-mask="'###########'" v-model="kayit.VergiNumarasi"
                                                        placeholder="Vergi Numaranız">
                                                    <p class="text-danger" v-if="v$.kayit.VergiNumarasi.$error">Vergi
                                                        numaranızı giriniz.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-sm-6">
                                                <router-link class="btn btn-light btn-block" to="/auth/loginPage">Giriş
                                                    Yap</router-link>
                                            </div>
                                            <div class="col-sm-6">
                                                <button type="submit" class="btn btn-primary btn-block">Başvuru
                                                    Yap</button>
                                            </div>
                                        </div>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- sign up page ends-->
        </div>
    </div>
    <!-- page-wrapper Ends-->
</template>

<script>
import { email, required, sameAs, minLength, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mask } from 'vue-the-mask'

export default {
    name: 'signUp',
    data() {
        return {
            kayit: {
                Unvan: "",
                YetkiliKisi: "",
                Sifre: "",
                SifreTekrar: "",
                Tel: "",
                GSM: "",
                Email: "",
                VergiDairesi: "",
                VergiNumarasi: "",
                Adres: ""
            }
        }
    },
    directives: {
        mask
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            kayit: {
                Unvan: { required },
                YetkiliKisi: { required },
                Sifre: { required },
                SifreTekrar: {
                    required,
                    sameAsPassword: sameAs(this.kayit.Sifre)
                },
                Tel: { required },
                GSM: { required },
                Email: { required, email },
                VergiDairesi: { required },
                VergiNumarasi: { required, minLength: minLength(10), maxLength: maxLength(11) },
                Adres: { required }
            }
        }
    },
    methods: {
        async onSubmit() {
            let vm = this;

            const isValid = await this.$validationHelper.waitForValidation(this, this.v$.kayit)
            if (isValid) {
                vm.$connection.post('/auth/create', vm.kayit, 'post', function (response) {
                    if (response.Success) {
                        vm.$alert.messageWithTitle('Başvuru', "Başvurunuz alındı. Onaylandığında sizi bilgilendireceğiz.", 'success');
                    } else {
                        vm.$alert.messageWithTitle('Başvuru', response.Message, 'error');
                    }
                });
            }
            else {
                vm.$alert.messageWithTitle('Başvuru', 'Lütfen formu tam olarak doldurunuz.', 'error');
            }
        }
    }
}
</script>
