<template>
    <div>
        <Breadcrumbs main="Sorgular" title="Liste" :links="breadLinks" />

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="card">
                        <div class="card-body">
                            <b-form @submit="onFiltreSubmit" inline>
                                <label class="sr-only" for="filtre_sorgu">Kelime</label>
                                <b-form-input type="text" id="filtre_sorgu" v-model="filtre.Sorgu" placeholder="Kelime"
                                    class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>

                                <label class="sr-only" for="filtre_tarihilk">Başlangıç Tarihi</label>
                                <datepicker :language="tr"
                                    input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                                    id="filtre_tarihilk" v-model="filtre.TarihIlk" placeholder="Başlangıç Tarihi"
                                    :format="date_format">
                                </datepicker>

                                <label class="sr-only" for="filtre_tarihson">Bitiş Tarihi</label>
                                <datepicker :language="tr"
                                    input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                                    id="filtre_tarihson" v-model="filtre.TarihSon" placeholder="Bitiş Tarihi"
                                    :format="date_format">
                                </datepicker>

                                <b-button type="submit" class="pull-right" variant="warning">Listele</b-button>
                            </b-form>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">

                            <c-table :tablefields="tablefields" :items="items">
                                <template #cell(OdemeDurumu)="data">
                                    <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                                </template>
                                <template #cell(KayitTarihi)="row">
                                    <c-tarih-cell :kayitTarihi="row.item.KayitTarihi"
                                        :rowKey="row.item.Id"></c-tarih-cell>
                                </template>
                            </c-table>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import { tr } from 'vuejs-datepicker/dist/locale'

export default {
    data() {
        return {
            breadLinks: [
            ],

            tablefields: [
                { key: 'SorguTipiStr', label: 'Sorgu Tipi', sortable: true, },
                { key: 'SorguKaydi', label: 'Sorgu', sortable: true, },
                { key: 'OdemeDurumu', label: 'Ödeme Durumu', sortable: true, },
                { key: 'KayitTarihi', label: 'Kayıt Tarihi', sortable: true }
            ],

            items: [],

            date_format: "dd.MM.yyyy",
            tr: tr,

            filtre: {
                Sorgu: null,
                TarihIlk: null,
                TarihSon: null
            }
        }
    },
    components: {
        Datepicker
    },
    mounted() {
        let baslangicTarihi = new Date();
        baslangicTarihi.setDate(-7);
        this.filtre.TarihIlk = baslangicTarihi;

        let bitisTarihi = new Date();
        this.filtre.TarihSon = bitisTarihi;

        this.onList();
    },
    methods: {
        onFiltreSubmit(evt) {
            evt.preventDefault();
            this.onList();
        },
        onList() {
            let vm = this;

            this.$connection.post("/sorgulama/listele", vm.filtre, "post", function (response) {
                if (response.Success) {
                    vm.items = response.Data;
                } else {
                    vm.$alert.messageWithTitle('Sorgu Listeleme', response.Message, 'error');
                }
            });
        }
    }
}
</script>