export default {
    validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    validPassword: function (password) {
        var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        return re.test(password);
    },
    waitForValidation: function(vm, object) {
        object.$touch();
        
        return new Promise((resolve) => {
            const unwatch = vm.$watch(() => !object.$pending, (isNotPending) => {
                if (isNotPending) {
                    //unwatch()
                    resolve(!object.$invalid)
                }
            }, { immediate: true })
        })
    }
}