export default {
    getUser() {
        if (localStorage.getItem('Token') === undefined || localStorage.getItem('Token') === null)
            return null;
        
        return JSON.parse(localStorage.getItem('Kullanici'));
    },
    setUser(data) {
        localStorage.setItem('Kullanici', JSON.stringify(data.Kullanici));
        localStorage.setItem('Token', data.Token);
    },
    logout() {
        localStorage.clear('Kullanici');
        localStorage.clear('Token');
    },
    getToken() {
        if (localStorage.getItem('Token') === undefined || localStorage.getItem('Token') === null)
            return null;
        
        return localStorage.getItem('Token');
    }
}