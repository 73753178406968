<template>
    <div>
        <Breadcrumbs main="Profil" title="Bilgiler" :links="breadLinks" />

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">

                    <b-card header-tag="div" class="odemeForm">
                        <h5 slot="header">Bilgileriniz</h5>
                        <b-form @submit.prevent="onBilgiSubmit">
                            <b-input-group prepend="Unvan" class="mb-3">
                                <b-form-input v-model="bilgiForm.Unvan" @blur="v$.bilgiForm.Unvan.$touch"
                                    placeholder="Firma Unvanı" :state="v$.bilgiForm.Unvan.$error == false"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Yetkili Kişi" class="mb-3">
                                <b-form-input v-model="bilgiForm.YetkiliKisi" @blur="v$.bilgiForm.YetkiliKisi.$touch"
                                    placeholder="Yetkili Kişi Adı Soyadı"
                                    :state="v$.bilgiForm.YetkiliKisi.$error == false"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Cep Telefonu" class="mb-3">
                                <b-form-input v-model="bilgiForm.GSM" v-mask="'0(5##) ### ####'" placeholder="Cep Telefonu"
                                    @blur="v$.bilgiForm.GSM.$touch"
                                    :state="v$.bilgiForm.GSM.$error == false"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Email Adresi" class="mb-3">
                                <b-form-input v-model="bilgiForm.Email" placeholder="Email Adresiniz"
                                    @blur="v$.bilgiForm.Email.$touch"
                                    :state="v$.bilgiForm.Email.$error == false"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Telefon" class="mb-3">
                                <b-form-input v-model="bilgiForm.Tel" v-mask="'(###) ### ## ##'"
                                    @blur="v$.bilgiForm.Tel.$touch" :state="v$.bilgiForm.Tel.$error == false"
                                    placeholder="Telefon Numarası"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Vergi Dairesi" class="mb-3">
                                <b-form-input v-model="bilgiForm.VergiDairesi" placeholder="Vergi Daireniz"
                                    @blur="v$.bilgiForm.VergiDairesi.$touch"
                                    :state="v$.bilgiForm.VergiDairesi.$error == false"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Vergi No" class="mb-3">
                                <b-form-input v-model="bilgiForm.VergiNumarasi"
                                    placeholder="T.C. Kimlik Numarası ya da Vergi Numarası"
                                    @blur="v$.bilgiForm.VergiNumarasi.$touch"
                                    :state="v$.bilgiForm.VergiNumarasi.$error == false"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Adresiniz" class="mb-3">
                                <b-form-textarea v-model="bilgiForm.Adres" placeholder="Fatura Adresiniz"
                                    @blur="v$.bilgiForm.Adres.$touch"
                                    :state="v$.bilgiForm.Adres.$error == false"></b-form-textarea>
                            </b-input-group>
                            <b-button type="submit" variant="primary">Güncelle</b-button>
                        </b-form>
                    </b-card>

                </div>
                <div class="col-md-6">

                    <b-card header-tag="div" class="odemeForm">
                        <h5 slot="header">Şifre Güncelleme</h5>
                        <b-form @submit.prevent="onSifreSubmit">
                            <b-input-group prepend="Eski Şifreniz" class="mb-3">
                                <b-form-input type="password" v-model="sifreForm.EskiSifre"
                                    @blur="v$.sifreForm.EskiSifre.$touch" placeholder="Eski Şifreniz"
                                    :state="v$.sifreForm.EskiSifre.$error == false"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Yeni Şifreniz" class="mb-3">
                                <b-form-input type="password" v-model="sifreForm.YeniSifre"
                                    @blur="v$.sifreForm.YeniSifre.$touch" placeholder="Yeni Şifreniz"
                                    :state="v$.sifreForm.YeniSifre.$error == false"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Yeni Şifre Tekrar" class="mb-3">
                                <b-form-input type="password" v-model="sifreForm.YeniSifreTekrar"
                                    @blur="v$.sifreForm.YeniSifreTekrar.$touch" placeholder="Yeni Şifrenizin Tekrarı"
                                    :state="v$.sifreForm.YeniSifreTekrar.$error == false"></b-form-input>
                            </b-input-group>
                            <b-button type="submit" variant="primary">Güncelle</b-button>
                        </b-form>
                    </b-card>

                    <b-card header-tag="div" class="odemeForm" v-if="musteri.APIKey === ''">
                        <h5 slot="header">Entegrasyon Kullanıcısı Oluştur</h5>
                        <b-form @submit.prevent="onApiSubmit">
                            <b-button type="submit" block variant="primary">Oluştur</b-button>
                        </b-form>
                    </b-card>

                    <b-card header-tag="div" class="odemeForm" v-if="musteri.APIKey !== ''">
                        <h5 slot="header">Entegrasyon Bilgileriniz</h5>
                        <div class="form-group">
                            <b-input-group prepend="Api Key">
                                <b-form-input :type="type" v-model="musteri.APIKey"></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="showPassword">
                                        <feather :type="type === 'password' ? 'eye' : 'eye-off'"></feather>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                    </b-card>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { email, required, sameAs, minLength, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mask } from 'vue-the-mask'

export default {
    data() {
        return {
            breadLinks: [
            ],

            type: 'password',

            musteri: {
                APIKey: ''
            },

            bilgiForm: {
                Unvan: "",
                YetkiliKisi: "",
                Tel: "",
                GSM: "",
                Email: "",
                VergiDairesi: "",
                VergiNumarasi: "",
                Adres: ""
            },

            sifreForm: {
                EskiSifre: "",
                YeniSifre: "",
                YeniSifreTekrar: ""
            }
        }
    },
    directives: {
        mask
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            bilgiForm: {
                Unvan: { required },
                YetkiliKisi: { required },
                Tel: { required },
                GSM: { required },
                Email: { required, email },
                VergiDairesi: { required },
                VergiNumarasi: { required, minLength: minLength(10), maxLength: maxLength(11) },
                Adres: { required }
            },
            sifreForm: {
                EskiSifre: { required },
                YeniSifre: { required },
                YeniSifreTekrar: {
                    required,
                    sameAsPassword: sameAs(this.sifreForm.YeniSifre)
                }
            }
        }
    },
    mounted() {
        this.onBilgi();
    },
    methods: {
        showPassword: function () {
            if (this.type === 'password') {
                this.type = 'text';
            } else {
                this.type = 'password';
            }
        },
        onBilgi() {
            let vm = this;

            this.$connection.get('/musteri/get', function (response) {
                if (response.Success) {
                    vm.musteri = { APIKey: response.Data.APIKey };

                    vm.bilgiForm = {
                        Unvan: response.Data.Unvan,
                        YetkiliKisi: response.Data.YetkiliKisi,
                        Tel: response.Data.Tel,
                        GSM: response.Data.GSM,
                        Email: response.Data.Email,
                        VergiDairesi: response.Data.VergiDairesi,
                        VergiNumarasi: response.Data.VergiNumarasi,
                        Adres: response.Data.Adres
                    }
                } else {
                    vm.$alert.messageWithTitle('Profil', response.Message, 'error');
                }
            });
        },
        async onBilgiSubmit() {
            let vm = this;

            const isValid = await this.$validationHelper.waitForValidation(this, this.v$.bilgiForm)
            if (isValid) {
                vm.$connection.post('/musteri/update', vm.bilgiForm, 'post', function (response) {
                    if (response.Success) {
                        vm.$alert.messageWithTitle('Profil', "Bilgileriniz güncellendi.", 'success');
                    } else {
                        vm.$alert.messageWithTitle('Profil', response.Message, 'error');
                    }
                });
            }
            else {
                vm.$alert.messageWithTitle('Başvuru', 'Lütfen formu tam olarak doldurunuz.', 'error');
            }
        },
        async onSifreSubmit() {
            let vm = this;

            const isValid = await this.$validationHelper.waitForValidation(this, this.v$.sifreForm)
            if (isValid) {
                vm.$connection.post('/musteri/sifreGuncelle', vm.sifreForm, 'post', function (response) {
                    if (response.Success) {
                        vm.$alert.messageWithTitle('Profil', "Şifreniz güncellendi.", 'success');
                    } else {
                        vm.$alert.messageWithTitle('Profil', response.Message, 'error');
                    }
                });
            }
            else {
                vm.$alert.messageWithTitle('Başvuru', 'Lütfen formu tam olarak doldurunuz.', 'error');
            }
        },
        async onApiSubmit() {
            let vm = this;

            vm.$connection.get('/musteri/addAPI', function (response) {
                if (response.Success) {
                    vm.musteri.APIKey = response.Data;

                    vm.$alert.messageWithTitle('Profil', "Entegrasyon bağlantısı oluşturuldu.", 'success');
                } else {
                    vm.$alert.messageWithTitle('Profil', response.Message, 'error');
                }
            });
        }
    }
}
</script>