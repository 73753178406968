import Vue from 'vue'
import Router from "vue-router";

import Body from '../components/body';
import auth from "../auth/auth";
import loginPage from "../auth/loginPage";
import Kayit from '../auth/kayit';
import sifreUnuttum from '../auth/sifreUnuttum';


/* Dashboards */
import Default from '../pages/dashboard/default'

import BakiyeDefault from '../pages/bakiye/default'
import SorguDefault from '../pages/sorgu/default'
import UserProfil from '../pages/user/profil'
import Entegrasyon from '../pages/entegrasyon/default'

// component

Vue.use(Router)

const routes = [
  { path: '', redirect: { name: 'default' } },
  {
    path: '/auth',
    component: auth,
    children: [
      {
        path: 'login',
        component: loginPage,
        meta: {
          title: ' Giriş | OtoArşiv',
        }
      },
      {
        path: 'kayit',
        name: 'kayit',
        component: Kayit,
        meta: {
          title: ' Kayıt | OtoArşiv',
        }
      },
      {
        path: 'sifreunuttum',
        name: 'sifreUnuttum',
        component: sifreUnuttum,
        meta: {
          title: ' Şifremi Unuttum | OtoArşiv',
        }
      }
    ]
  },

  {
    path: '/dashboard',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'default',
        name: 'default',
        component: Default,
        meta: {
          title: 'Kontrol Paneli',
        }
      },
    ]
  },
  {
    path: '/bakiye',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'default',
        name: 'bakiyeDefault',
        component: BakiyeDefault,
        meta: {
          title: 'Bakiye',
        }
      },
    ]
  },
  {
    path: '/sorgu',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'default',
        name: 'sorguDefault',
        component: SorguDefault,
        meta: {
          title: 'Sorgular',
        }
      },
    ]
  },
  {
    path: '/user',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'profil',
        name: 'userProfil',
        component: UserProfil,
        meta: {
          title: 'Profil',
        }
      },
    ]
  },
  {
    path: '/entegrasyon',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'entegrasyon',
        component: Entegrasyon,
        meta: {
          title: 'Entegrasyon',
        }
      },
    ]
  }

];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});


router.beforeEach((to, from, next) => {

  const path = ['/auth/login', '/auth/kayit','/auth/sifreunuttum'];
  if (path.includes(to.path) || localStorage.getItem('Token')) {
    return next();
  }
  next('/auth/login');
});


export default router
