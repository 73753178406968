<template>
    <b-card header-tag="div" class="odemeForm">
        <h5 slot="header">Ödeme Yap</h5>
        <b-form @submit.prevent="sorguSubmit">
            <b-input-group prepend="Ödeme Tutarı" append="₺" class="mb-3">
                <b-form-input v-model="odeme.Tutar" @blur="v$.odeme.Tutar.$touch"
                    placeholder="Ödemek istediğiniz tutarı yazınız." :state="v$.odeme.Tutar.$error == false"></b-form-input>
            </b-input-group>
            <b-input-group prepend="Kart Sahibi" class="mb-3">
                <b-form-input v-model="odeme.KartSahibi" @blur="v$.odeme.KartSahibi.$touch"
                    placeholder="Kart üzerindeki ad soyadı yazınız."
                    :state="v$.odeme.KartSahibi.$error == false"></b-form-input>
            </b-input-group>
            <b-input-group prepend="Kart Numarası" class="mb-3">
                <b-form-input v-model="odeme.KartNumarasi" v-mask="'#### #### #### ####'"
                    @blur="v$.odeme.KartNumarasi.$touch" placeholder="Kart numarasını yazınız."
                    :state="v$.odeme.KartNumarasi.$error == false"></b-form-input>
            </b-input-group>
            <div class="form-row">
                <div class="col-md-6">
                    <b-input-group prepend="Ay / Yıl (Ör:03/24)" class="mb-3">
                        <b-form-input v-model="odeme.AyYil" v-mask="'##/##'" @blur="v$.odeme.AyYil.$touch"
                            placeholder="Ay/Yıl" :state="v$.odeme.AyYil.$error == false"></b-form-input>
                    </b-input-group>
                </div>
                <div class="col-md-6">
                    <b-input-group prepend="Güvenlik Kodu (CVC)" class="mb-3">
                        <b-form-input v-model="odeme.Cvc" v-mask="'###'" @blur="v$.odeme.Cvc.$touch"
                            placeholder="3 haneli güvenlik kodunu yazınız."
                            :state="v$.odeme.Cvc.$error == false"></b-form-input>
                    </b-input-group>
                </div>
            </div>
            <button type="submit" class="btn btn-primary btn-block">Ödeme Yap</button>
        </b-form>
    </b-card>
</template>
<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mask } from 'vue-the-mask'

export default {
    props: {},
    data() {
        return {
            odeme: {
                MusteriId: 0,
                Tutar: 1000,
                KartSahibi: "",
                KartNumarasi: "",
                Ay: 0,
                Yil: 0,
                AyYil: "",
                Cvc: ""
            }
        }
    },
    directives: {
        mask
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            odeme: {
                Tutar: { required },
                KartSahibi: { required },
                KartNumarasi: { required },
                AyYil: { required },
                Cvc: { required }
            }
        }
    },
    mounted() {
        this.v$.$touch();
    },
    components: {

    },
    methods: {
        async sorguSubmit() {
            let vm = this;

            const isValid = await this.$validationHelper.waitForValidation(this, this.v$)
            if (isValid) {
                let ayYil = vm.odeme.AyYil.split("/");
                vm.odeme.Ay = parseInt(ayYil[0]);
                vm.odeme.Yil = parseInt(ayYil[1]);

                vm.odeme.Tutar = parseFloat(vm.odeme.Tutar);

                this.$connection.post('/odeme/bakiyeEkle', vm.odeme, 'post', function (response) {
                    console.log(response);
                    if (response.Success) {
                        let data = response.Data;

                        window.location.href = data.PostUrl;
                    } else {
                        vm.$alert.messageWithTitle('Sorgu', response.Message, 'error');
                    }
                });
            }
            else {
                vm.$alert.messageWithTitle('Sorgu', 'Lütfen formu tam olarak doldurunuz.', 'error');
            }
        }
    }
}
</script>
<style>
.odemeForm .input-group-prepend {
    width: 200px;
}

.odemeForm .input-group-prepend .input-group-text {
    width: 100%;
}</style>