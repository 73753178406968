<template>
    <div>
        <feather type="check-square" class="font-success f-24" v-if="isAktif"></feather>
        <feather type="x" class="font-danger f-24" v-else></feather>
    </div>
</template>
<script>
export default {
    props: {
        isAktif: false
    }
}
</script>