export default {
    formatMoney: function (n) {
        return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1');
    },
    basHarfler: function(adSoyad) {
        let arrAdSoyad = adSoyad.split(' ');
        let result = '';
        for (let i = 0; i < arrAdSoyad.length; i++) {
            result += arrAdSoyad[i].substring(0, 1).toUpperCase();
        }
        return result;
    },
}